import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ApolloProvider} from 'react-apollo';
import {client} from './src/apollo/client';
import './src/styles/global.scss';

export const onClientEntry = () => {
  if (!('IntersectionObserver' in window)) {
    require('intersection-observer');
    console.log('# IntersectionObserver is polyfilled!');
  }
};

export const wrapRootElement = ({element}) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);
