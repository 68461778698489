// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-beta-index-js": () => import("./../../../src/pages/beta/index.js" /* webpackChunkName: "component---src-pages-beta-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contacts-index-js": () => import("./../../../src/pages/contacts/index.js" /* webpackChunkName: "component---src-pages-contacts-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-feedback-index-js": () => import("./../../../src/pages/feedback/index.js" /* webpackChunkName: "component---src-pages-feedback-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-notice-index-js": () => import("./../../../src/pages/privacy-notice/index.js" /* webpackChunkName: "component---src-pages-privacy-notice-index-js" */),
  "component---src-pages-tutorial-index-js": () => import("./../../../src/pages/tutorial/index.js" /* webpackChunkName: "component---src-pages-tutorial-index-js" */)
}

