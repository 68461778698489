import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

// stage server
// const API_URL = 'https://cellar-ventures-stage.herokuapp.com/graphql';

// dev server
// const API_URL = 'https://ei-dev-cellar-ventures.herokuapp.com/graphql';

// prod server
const API_URL = 'https://cellar-ventures.herokuapp.com/graphql';

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({uri: API_URL})
})
